<template>
  <div class="page-content-ressources">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr'
            ? 'Gestion des ressources'
            : 'Resource Management'
        }}
      </div>
      <div v-if="hasError" class="error-msg">
        <ul v-if="Array.isArray(errorMessages)">
          <li v-for="(e, index) in errorMessages" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ errorMessages }}</span>
      </div>
      <v-btn
        v-if="selectedMenu == 'resources'"
        class="btn-filter"
        dark
        color="#5C2DD3"
        @click.prevent.stop="handleAddResource"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{ $i18n.locale === 'fr' ? 'Ajouter une ressource' : 'Add resource' }}
      </v-btn>

      <v-btn
        v-if="selectedMenu == 'type-resources'"
        @click.prevent.stop="handleAddTypeResource"
        class="btn-filter"
        dark
        color="#5C2DD3"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale === 'fr'
            ? 'Ajouter un type de ressources'
            : 'Add resource type'
        }}
      </v-btn>
    </div>
    <div class="block-menu-users mt-2">
      <div class="list-tabs style-tabs">
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            href="#tab-0"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('resources')"
            to="/setting/resource/resources"
          >
            <div class="menu-item">
              {{ $i18n.locale === 'fr' ? 'ressources' : 'Resource' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-1"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('type-resources')"
            to="/setting/resource/type-resources"
          >
            <div class="menu-item">
              {{ $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type' }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
    <!-- Add Resoource Modal -->
    <v-dialog v-model="modalAddRessource" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Ajouter une ressource' : 'Add resource'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalAddRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalAddRessource" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM RESOURCE -->
                <!-- <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="resourceToAdd.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field> -->
                <!-- DESCRIPTION -->
                <!-- <v-textarea
                  dense
                  label="Description"
                  v-model="resourceToAdd.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea> -->
                <!-- TYPE RESOURCE -->
                <v-autocomplete
                  class="mt-3"
                  :clearable="true"
                  color="#5C2DD3"
                  @input="changeFieldsTypeResource"
                  :loading="getTypeResourceLoading"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="resourceToAdd.type"
                  :items="getTypeResource"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
                  "
                  item-text="designation"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'type ressources'
                        : 'resource type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                resourceToAdd.type &&
                  resourceToAdd.type.fields &&
                  resourceToAdd.type.fields.length
              "
            >
              <v-row
                v-for="field in resourceToAdd.type.fields"
                :key="'field-add-' + field.id + `${resourceToAdd.type.id}`"
              >
                <v-col>
                  <div class="value">
                    <EditableCustomFieldForm
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="resourceToAdd.type.fields"
                      @updateFunction="updateFunction"
                      :fieldKeyEdit="false"
                      :required="field.required"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- ACTIVE / DESACTIVE RESOURCE -->
            <v-switch
              :label="
                !resourceToAdd.active
                  ? $i18n.locale == 'fr'
                    ? 'Désactivé'
                    : 'Disabled'
                  : $i18n.locale == 'fr'
                  ? 'Active'
                  : 'Active'
              "
              color="#5C2DD3"
              v-model="resourceToAdd.active"
              :class="{ 'color-bold': resourceToAdd.active }"
              class="bold-700 ml-2 margin-switch-input"
              :true-value="1"
              :false-value="0"
            >
            </v-switch>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderAddRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalAddRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Resoource Modal -->
    <!-- Add Type Resoource Modal -->
    <v-dialog
      v-model="modalAddTypeRessource"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Ajouter un type de ressources'
                : 'Add resource type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalAddTypeRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalAddTypeRessource" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM TYPE RESOURCE -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="typeResourceToAdd.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPE PROJET -->
                <v-select
                  v-model="typeResourceToAdd.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="typeResourceToAdd.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeResourceError" class="error-msg">
              <ul v-if="Array.isArray(getTypeResourceError)">
                <li v-for="(e, index) in getTypeResourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeResourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderAddTypeRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalAddTypeRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Type Resoource Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  data() {
    return {
      model: 'tab-0',
      selectedMenu:
        this.$router.history.current.name === 'type-resources'
          ? 'type-resources'
          : 'resources',
      resourceToAdd: {
        // designation: null,
        // detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1
      },
      typeResourceToAdd: {
        designation: null,
        detail: null
      },
      modalAddRessource: false,
      modalAddTypeRessource: false,
      loading: false,
      errorMessages: null
    }
  },

  methods: {
    ...mapActions([
      'addResource',
      'resetErrorResource',
      'fetchAllTypeResource',
      'addTypeResource',
      'resetErrorTypeResource',
      'fetchAllResource',
      'fetchOneTypeResource',
      'resetErrorTypeResource'
    ]),
    async changeFieldsTypeResource(field) {
      if (field) {
        await this.fetchOneTypeResource(field.id)
      }
    },
    updateFunction(field) {
      this.resourceToAdd.fields = field
    },
    clickMenu(menu) {
      this.selectedMenu = menu
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalAddRessource') {
        this.$refs.modalAddRessource.resetValidation()
        this.resetErrorResource()
        this.resetErrorTypeResource()
      }
      if (ref == 'modalAddTypeRessource') {
        this.$refs.modalAddTypeRessource.resetValidation()
        this.resetErrorTypeResource()
      }
      this.resetFieldsModal()
    },
    resetFieldsModal() {
      this.resourceToAdd = {
        // designation: null,
        // detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1
      }
      this.typeResourceToAdd = {
        designation: null,
        detail: null
      }
      this.loading = false
    },
    handleAddResource() {
      this.modalAddRessource = true
    },
    async handleValiderAddRessource() {
      if (this.$refs.modalAddRessource.validate()) {
        this.loading = true
        const response = await this.addResource(this.resourceToAdd)
        if (response) {
          this.closeDialog('modalAddRessource')
          this.fetchAllResource({
            page: 1,
            per_page: 10,
            active: 1
          })
        }
        this.loading = false
      }
    },
    handleAddTypeResource() {
      this.modalAddTypeRessource = true
      if (!this.getResource || !this.getResource.length) {
        this.fetchAllResource({
          page: 1,
          per_page: 10,
          active: 1
        })
      }
    },
    async handleValiderAddTypeRessource() {
      if (this.$refs.modalAddTypeRessource.validate()) {
        this.loading = true
        const body = {
          designation: this.typeResourceToAdd.designation,
          detail: this.typeResourceToAdd.detail,
          type_projet_id:
            this.typeResourceToAdd &&
            this.typeResourceToAdd.type_projet &&
            this.typeResourceToAdd.type_projet.id
              ? this.typeResourceToAdd.type_projet.id
              : this.typeResourceToAdd.type_projet
        }
        const response = await this.addTypeResource(body)
        if (response) {
          this.fetchAllTypeResource({
            page: 1,
            per_page: 10
          })
          this.closeDialog('modalAddTypeRessource')
        }
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getResourceLoading',
      'getResourceError',
      'getResource',
      'getTypeResourceError',
      'getTypeResourceLoading',
      'getTypeResource',
      'getProjectsTypes',
      'getProjectsTypesLoading'
    ]),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getResourceError(newValue) {
      this.errorMessages = newValue
    },
    getTypeResourceError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    if (!this.getTypeResource || !this.getTypeResource.length) {
      this.fetchAllTypeResource()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content-ressources {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
</style>
