<template>
  <div class="page-content-event-type">
    <div class="header pb-3">
      <div>
        <div class="style-title-setting-global">
          {{
            $i18n.locale === 'fr'
              ? "Gestion des types d'événements"
              : 'Event type Management'
          }}
        </div>
        <div class="message mt-2">
          <div v-if="getTypeEventError" class="error-msg">
            <ul v-if="Array.isArray(getTypeEventError)">
              <li v-for="(e, index) in getTypeEventError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTypeEventError }}</span>
          </div>
        </div>
      </div>
      <div class="float-right flex-center">
        <v-progress-circular
          v-if="getTypeEventLoading && !initLoading && !loading"
          class="mr-3"
          indeterminate
          color="#5C2DD3"
        ></v-progress-circular>
        <v-btn
          @click.prevent.stop="handleAddTypeResource"
          class="btn-filter"
          dark
          color="#5C2DD3"
        >
          <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
          {{
            $i18n.locale === 'fr'
              ? "Ajouter un type d'événement"
              : 'Add event type'
          }}
        </v-btn>
      </div>
    </div>

    <div class="setting-event-type">
      <div class="loader-content" v-if="initLoading">
        <v-progress-circular
          indeterminate
          class="ml-3"
          color="#5C2DD3"
        ></v-progress-circular>
      </div>
      <div class="header-team mt-3" v-else>
        <v-list
          dense
          class="list-ensemble mt-3"
          v-if="getTypeEvent && getTypeEvent.length"
        >
          <v-list-item
            class="list-ensemble-item mb-3 mr-3"
            v-for="(typeEvent, index) in getTypeEvent"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title class="flex center">
                <div class="font-text font-sz-14 bold-700 text-capitalize">
                  {{ typeEvent.designation }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="icon-action mr-2"
                small
                outlined
                color="#5C2DD3"
                :title="
                  $i18n.locale == 'fr'
                    ? 'Configuration des champs'
                    : 'Field configuration'
                "
                @click.prevent.stop="handleSettingClick(typeEvent)"
              >
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                class="icon-action mr-2"
                small
                outlined
                color="#5C2DD3"
                :title="$t('update')"
                @click.prevent.stop="handleUpdateClick(typeEvent)"
              >
                <font-awesome-icon class="img-h-19" icon="pencil-alt" />
              </v-btn>
            </v-list-item-action>
            <v-list-item-action class="ml-0">
              <v-btn
                class="icon-action"
                :title="$t('deleted')"
                small
                outlined
                color="#5C2DD3"
                @click.prevent.stop="handleDeleteClick(typeEvent)"
              >
                <font-awesome-icon class="img-h-19" icon="trash-alt" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else class="div-aucun-list">
          <div class="titre">
            {{
              $i18n.locale === 'fr' ? "Aucun type d'événement" : 'No event type'
            }}
          </div>
        </div>
        <div
          class="footer-style-table"
          v-if="getTypeEvent && getTypeEvent.length"
        >
          <div class="text-center pagination-table">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="pagination"
              color="#5C2DD3"
              item-color="#5C2DD3"
            ></v-pagination>
          </div>
          <div class="select-input-vuetify">
            <v-select
              class="per-page-element-style"
              v-model="per_page"
              :items="perPageList"
              @change="changePerPage"
              :label="
                $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
              "
              outlined
              dense
              hide-details
              :no-data-text="
                $i18n.locale === 'fr'
                  ? 'Aucun élément trouvé'
                  : 'No items found'
              "
              :menu-props="{
                left: true,
                offsetX: true
              }"
              color="#5C2DD3"
              item-color="#5C2DD3"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Type Resoource Modal -->
    <v-dialog v-model="modalAddTypeEvent" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Ajouter un type d'événement"
                : 'Add event type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalAddTypeEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalAddTypeEvent" class="mt-2">
            <!-- NOM TYPE EVENT -->
            <v-row class="row">
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="typeEventToAdd.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- TYPE PROJET -->
            <v-row>
              <v-col>
                <v-select
                  v-model="typeEventToAdd.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
              </v-col>
            </v-row>
            <!-- ICON && COLOR  -->
            <v-row>
              <v-col cols="9">
                <v-combobox
                  :clearable="true"
                  color="#5C2DD3"
                  placeholder="Rechercher une icon"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  dense
                  outlined
                  v-model="typeEventToAdd.icon"
                  :items="iconsList"
                  label="Icon"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>
                            <font-awesome-icon
                              :icon="[
                                getIconFormate(item, 0),
                                getIconFormate(item, 1)
                              ]"
                              class="mr-2"
                            ></font-awesome-icon>
                            {{ getIconFormate(item, 1, true) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>
                      <font-awesome-icon
                        :icon="[
                          getIconFormate(item, 0),
                          getIconFormate(item, 1)
                        ]"
                        class="mr-2"
                      ></font-awesome-icon>
                      {{ getIconFormate(item, 1, true) }}
                    </span>
                  </template>
                </v-combobox>
              </v-col>
              <v-col class="shrink" cols="3">
                <customColorPicker
                  v-model="typeEventToAdd.color"
                ></customColorPicker>
              </v-col>
            </v-row>
            <!-- ACTION -->
            <!-- <v-row>
              <v-col>
                <v-select
                  v-model="typeEventToAdd.action_id"
                  :label="$t('action')"
                  dense
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('action')
                    })
                  "
                  item-text="name"
                  item-value="id"
                  :items="getActionForTypeEvent"
                  outlined
                  :loading="getActionForTypeEventLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  validate-on-blur
                ></v-select>
              </v-col>
            </v-row> -->
            <!-- DESCRIPTION -->
            <v-row class="row">
              <v-col>
                <v-textarea
                  dense
                  label="Description"
                  v-model="typeEventToAdd.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeEventError" class="error-msg">
              <ul v-if="Array.isArray(getTypeEventError)">
                <li v-for="(e, index) in getTypeEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderAddTypeEvent"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalAddTypeEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Type Resoource Modal -->
    <!-- delete type event -->
    <v-dialog
      v-model="modalDeletedTypeEvent"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Supprimer un type d'événement"
                : 'Delete event type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalDeletedTypeEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? "le type d'événement :"
                    : 'the event type :'
              })
            }}
            <strong class="text-capitalize">
              {{
                typeEventToDeleted && typeEventToDeleted.designation
                  ? typeEventToDeleted.designation
                  : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeEventError" class="error-msg">
              <ul v-if="Array.isArray(getTypeEventError)">
                <li v-for="(e, index) in getTypeEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeletedTypeResource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalDeletedTypeEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Type event Modal -->
    <v-dialog
      v-model="modalUpdateTypeEvent"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Modifier un type d'événements"
                : 'Edit event type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalUpdateTypeEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form
            ref="modalUpdateTypeEvent"
            class="mt-2"
            v-if="typeEventToUpdate"
          >
            <!-- NOM TYPE EVENT -->
            <v-row class="row">
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="typeEventToUpdate.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- TYPE PROJET -->
            <v-row>
              <v-col>
                <v-select
                  v-model="typeEventToUpdate.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
              </v-col>
            </v-row>
            <!-- ICON && COLOR  -->
            <v-row>
              <v-col cols="9">
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  placeholder="Rechercher une icon"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  dense
                  outlined
                  v-model="typeEventToUpdate.icon"
                  :items="iconsList"
                  label="Icon"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <font-awesome-icon
                              :icon="[
                                getIconFormate(item, 0),
                                getIconFormate(item, 1)
                              ]"
                              class="mr-2"
                            ></font-awesome-icon>
                            {{ getIconFormate(item, 1, true) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </template>

                  <template v-slot:selection="{ item }">
                    <span>
                      <font-awesome-icon
                        :icon="[
                          getIconFormate(item, 0),
                          getIconFormate(item, 1)
                        ]"
                        class="mr-2"
                      ></font-awesome-icon>
                      {{ getIconFormate(item, 1, true) }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="shrink" cols="3">
                <customColorPicker
                  v-model="typeEventToUpdate.color"
                ></customColorPicker>
              </v-col>
            </v-row>
            <!-- ACTION -->
            <!-- <v-row>
              <v-col>
                <v-select
                  v-model="typeEventToUpdate.action_id"
                  :label="$t('action')"
                  dense
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('action')
                    })
                  "
                  item-text="name"
                  item-value="id"
                  :items="getActionForTypeEvent"
                  outlined
                  :loading="getActionForTypeEventLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  validate-on-blur
                ></v-select>
              </v-col>
            </v-row> -->
            <!-- DESCRIPTION -->
            <v-row class="row">
              <v-col>
                <v-textarea
                  dense
                  label="Description"
                  v-model="typeEventToUpdate.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getTypeEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getTypeEventError" class="error-msg">
              <ul v-if="Array.isArray(getTypeEventError)">
                <li v-for="(e, index) in getTypeEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getTypeEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderUpdateTypeEvent"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalUpdateTypeEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import iconList from '@/icons/iconsList.json'
export default {
  data() {
    return {
      modalAddTypeEvent: false,
      loading: false,
      typeEventToAdd: {
        designation: null,
        detail: null,
        type_projet: null,
        icon: null,
        color: 'transparent',
        action_id: null
      },
      initLoading: true,
      typeEventToDeleted: null,
      typeEventToUpdate: null,
      typeEventToUpdateOriginal: null,
      modalDeletedTypeEvent: false,
      modalUpdateTypeEvent: false,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ],
      iconsList: iconList
    }
  },
  methods: {
    ...mapActions([
      'addTypeEvent',
      'fetchAllTypeEvent',
      'deletedTypeEvent',
      'updateTypeEvent',
      'resetErrorTypeEvent',
      'fetchProjetsTypes',
      'fetchActionsForTypeEvent'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchAllTypeEvent({
        page: this.page,
        per_page: this.per_page
      })
    },
    changePerPage() {
      this.fetchAllTypeEvent({
        page: this.page,
        per_page: this.per_page
      })
    },
    resetFieldModal() {
      this.typeEventToDeleted = null
    },
    handleDeleteClick(typeEvent) {
      this.typeEventToDeleted = typeEvent
      this.modalDeletedTypeEvent = true
    },
    async handleDeletedTypeResource() {
      this.loading = true
      const response = await this.deletedTypeEvent(this.typeEventToDeleted.id)
      if (response) {
        this.closeDialog('modalDeletedTypeEvent')
        this.fetchAllTypeEvent({
          page: this.page,
          per_page: this.per_page
        })
      }
      this.loading = false
    },
    handleUpdateClick(typeEvent) {
      this.typeEventToUpdate = { ...typeEvent }
      this.typeEventToUpdateOriginal = typeEvent
      this.typeEventToUpdate.type_projet = this.typeEventToUpdate.type_projet_id
      this.fetchActionsForTypeEvent()
      this.modalUpdateTypeEvent = true
    },
    async handleValiderUpdateTypeEvent() {
      if (this.$refs.modalUpdateTypeEvent.validate()) {
        this.loading = true
        const body = {
          designation: this.typeEventToUpdate.designation,
          detail: this.typeEventToUpdate.detail,
          color: this.typeEventToUpdate.color,
          icon: this.typeEventToUpdate.icon,
          action_id: this.typeEventToUpdate.action_id,
          type_projet_id:
            this.typeEventToUpdate &&
            this.typeEventToUpdate.type_projet &&
            this.typeEventToUpdate.type_projet.id
              ? this.typeEventToUpdate.type_projet.id
              : this.typeEventToUpdate.type_projet
        }
        const response = await this.updateTypeEvent({
          body,
          typeEvent: this.typeEventToUpdateOriginal
        })
        if (response) {
          this.closeDialog('modalUpdateTypeEvent')
        }
        this.loading = false
      }
    },
    handleSettingClick(typeEvent) {
      this.$router.push('/setting/type-evenements/fields/' + typeEvent.id)
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalAddTypeEvent') {
        this.$refs.modalAddTypeEvent.resetValidation()
      }
      if (ref == 'modalUpdateTypeEvent') {
        this.$refs.modalUpdateTypeEvent.resetValidation()
      }
      this.resetModal()
      this.resetFieldModal()
      this.resetErrorTypeEvent()
    },
    nameWithLang({ name }) {
      return `${name}`
    },
    async handleValiderAddTypeEvent() {
      if (this.$refs.modalAddTypeEvent.validate()) {
        this.loading = true
        const response = await this.addTypeEvent(this.typeEventToAdd)
        if (response) {
          this.closeDialog('modalAddTypeEvent')
          this.fetchAllTypeEvent({
            page: this.page,
            per_page: this.per_page
          })
        }
        this.loading = false
      }
    },
    async handleAddTypeResource() {
      this.modalAddTypeEvent = true
      this.fetchActionsForTypeEvent()
    },
    resetModal() {
      this.typeEventToAdd = {
        designation: null,
        detail: null,
        type_projet: null,
        icon: null,
        color: 'transparent',
        action_id: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTypeEventLoading',
      'getTypeEventError',
      'getTypeEvent',
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'countPaginationTypeEvent',
      'getIconFormate',
      'getActionForTypeEvent',
      'getActionForTypeEventLoading'
    ]),
    totalPages() {
      if (this.countPaginationTypeEvent) {
        return Math.ceil(this.countPaginationTypeEvent / this.per_page)
      }
      return this.countPaginationTypeEvent
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchAllTypeEvent({
      page: this.page,
      per_page: this.per_page
    })
    this.fetchProjetsTypes()
    this.initLoading = false
  },
  components: {
    customColorPicker: () => import('@/components/customColorPicker.vue')
  }
}
</script>

<style lang="scss" scoped>
.page-content-event-type {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .setting-event-type {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .v-btn:not(.v-btn--round).v-size--small {
        height: 30px;
        min-width: 30px;
      }
    }
    .list-ensemble-item {
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #d8d8d8;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      border-color: #d8d8d8 !important;
    }
    .list-ensemble {
      max-height: calc(100vh - 278px) !important;
      height: calc(100vh - 278px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      padding: 0px 0px;
      cursor: pointer;
    }
    .list-ensemble::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .list-ensemble::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .list-ensemble::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
}
</style>
<style lang="scss">
.setting-event-type {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
